import React from "react"
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components'
import dnCold from "../../images/CS_day-night-cold.png"
import dnHot from "../../images/CS_day-night-hot.png"

const fadeAnimation = keyframes`
    1% {opacity: 1;}
    50% {opacity: 1;}
    51% {opacity: 0;}
    100% {opacity: 0;}
`;

const DayNight = styled.div`
    position: relative;
    max-width: 100%;
`;

const DayNightImage = styled.img`
    position: ${p => p.isSecondary ? 'absolute' : 'initial'};
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    animation: ${fadeAnimation} 4s linear;
    animation-delay: ${p => p.isSecondary ? '2s' : 'none'};
    animation-iteration-count: infinite;
`;

DayNightImage.propTypes = {
    isSecondary: PropTypes.bool,
};

DayNightImage.defaultProps = {
    isSecondary: false,
};

const DayNightCycle = () => (
    <DayNight>
        <DayNightImage src={dnHot} alt="" />
        <DayNightImage src={dnCold} isSecondary alt="" />
    </DayNight>
)

export default DayNightCycle;