import React from "react"
import styled, { css } from "styled-components";
import Button from "../Button/button"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const Container = styled.section`
    width: 100%;
    background-color: ${props => props.type === "Press" ? "var(--neutral-100)" : 'var(--neutral-200)' };
    margin: auto;
    ${props => props.type === 'Press' && css`
        scroll-margin-top: 100px; // Height of the header + top padding.
    `}
    h1{
        text-align: center;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    h2 {
        margin: 10px 0;
    }
    p {
        margin: ${props => props.type === "Press" ? "0" : "10px 0"};
        text-transform: ${props => props.type === "Press" ? "uppercase" : "none"};
        font-size: ${props => props.type === "Press" ? "16px" : "18px"};
        font-weight: ${props => props.type === "Press" ? "300" : "400"};
    }
    div.underline {
        margin-top: -3px;
    }
`;

const ThreeColArea = styled.div`
    width: 70%;
    max-width: 1080px;
    margin: 0 auto;
    padding-top: 20px;
    display: block;
    justify-content: space-between;
    align-items: stretch;
    text-align: center;
    @media only screen and (min-width: 800px) {
        display: flex;
        width: 90%;
    }
`;

const ThreeColItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin-bottom: 40px;
    img {
    width:100%;
    }
    h2 {
    align-self: center;
    padding: 0 10px;
    font-family: 'Open Sans', sans-serif;
    }
    @media only screen and (min-width: 800px) {
        width: 30%;
        h2 {
        font-size: calc(7px + 0.8vw);
        }
    }
    @media only screen and (min-width: 1600px) {
        h2 {
            font-size: 20px;
        }
    }
`;

const TxtContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    a {
        text-transform: uppercase;
        text-decoration: none;
        color: var(--accent-orange-500);
    }
    a:hover {
        text-decoration: underline;
    }
`;

const SummaryArea = styled.div`
    width: 80%;
    margin: 0 auto;
    max-width: 850px;
    padding-bottom: 2rem;
`;

const Summary = styled.p`
    text-align: center;
`;

const ButtonBox = styled.div`
    width: 80%;
    max-width: 850px;
    margin: 0 auto;
    padding-bottom:2em;
    text-align: center;
`;


export default function ThreeCol(props) {
    const data = useStaticQuery(graphql`
        query {
            solarPanel: file(relativePath: {eq: "images/solar-panel.png"}) {
                childImageSharp {
                    fluid(maxWidth: 560, quality: 72) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
            earthEnergyBank: file(relativePath: {eq: "images/earth-energy-bank.png"}) {
                childImageSharp {
                    fluid(maxWidth: 560, quality: 72) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
            heatPump: file(relativePath: {eq: "images/heat-pump.png"}) {
                childImageSharp {
                    fluid(maxWidth: 560, quality: 72) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
            financialTimes: file(relativePath: {eq: "images/FT.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 560, quality: 72) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
            telegraph: file(relativePath: {eq: "images/telegraph.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 560, quality: 72) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
            skyNews: file(relativePath: {eq: "images/skynews.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 560, quality: 72) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    `)
    if (props.type === "Press") {
        return (
            <Container type={props.type} id={props.anchorId}>
                <h1>Press coverage</h1>
                <div className="underline center"></div>
                <ThreeColArea>
                    <ThreeColItem>
                        <a href="https://www.telegraph.co.uk/finance/property/green/10377334/Eco-living-the-Leicestershire-home-that-powers-itself.html" target="_blank" rel="noopener noreferrer">
                            <Img fluid={data.telegraph.childImageSharp.fluid} alt="The Telegraph" />
                        </a>
                        <TxtContainer>
                            <h2>The Telegraph</h2>
                            <p>Eco Living: The Leicestershire home that powers itself</p>
                        </TxtContainer>
                    </ThreeColItem>
                    <ThreeColItem>
                        <a href="https://youtu.be/d9nQEwwrJGQ" target="_blank" rel="noopener noreferrer">
                            <Img fluid={data.skyNews.childImageSharp.fluid} alt="Sky News" />
                        </a>
                        <TxtContainer>
                            <h2>Sky News</h2>
                            <p>Swipe: Green energy & Solar-Powered Homes</p>
                        </TxtContainer>
                    </ThreeColItem>
                    <ThreeColItem>
                        <a href="https://www.ft.com/content/b7cb7a4e-8422-11e3-9903-00144feab7de" target="_blank" rel="noopener noreferrer">
                            <Img fluid={data.financialTimes.childImageSharp.fluid} alt="Financial Times" />
                        </a>
                        <TxtContainer>
                            <h2>Financial Times</h2>
                            <p>Solar-Powered Homes</p>
                        </TxtContainer>
                    </ThreeColItem>
                </ThreeColArea>
            </Container>
        )
    } else if (props.type === "Technology") {
        return (
            <Container type={props.type}>
                {
                    props.concise ? (
                        <>
                            <h1>The system consists of three core components:</h1>
                            <div className="underline center"></div>
                        </>
                    ) : (<></>)
                }
                <ThreeColArea>
                    <ThreeColItem>
                        <Link to="/technology/collection">
                            <Img fluid={data.solarPanel.childImageSharp.fluid} alt="PV-T panel" />
                        </Link>
                        <TxtContainer>
                            <h2>Solar Energy Collection</h2>
                            <p>Advanced solar energy capture, collecting both electrical and thermal energy, using <Link to="/technology/collection">hybrid PV-T</Link> panel arrays.</p>
                        </TxtContainer>
                    </ThreeColItem>
                    <ThreeColItem>
                        <Link to="/technology/storage">
                            <Img fluid={data.earthEnergyBank.childImageSharp.fluid} alt="Earth Energy Bank" />
                        </Link>
                        <TxtContainer>
                            <h2>Thermal Energy Storage</h2>
                            <p>Our patented <Link to="/technology/storage">Earth Energy Bank</Link>, which provides inter-seasonal energy storage within the footprint of the building.</p>
                        </TxtContainer>
                    </ThreeColItem>
                    <ThreeColItem>
                        <Link to="/technology/recovery">
                            <Img fluid={data.heatPump.childImageSharp.fluid} alt="Heat pump" />
                        </Link>
                        <TxtContainer>
                            <h2>Heat Pump</h2>
                            <p>Energy recovery using a conventional ground source <Link to="/technology/recovery">heat pump</Link>, within a system that enhances its efficiency.</p>
                        </TxtContainer>
                    </ThreeColItem>
                </ThreeColArea>
                {
                    props.concise ? (<></>) : (
                        <>
                            <SummaryArea>
                                <Summary style={{marginTop: 0, fontSize: 20}}>This multi-award winning system is scalable and economical​ and can meet a home’s full annual heating and hot water requirements using just solar energy.</Summary>
                            </SummaryArea>
                            <ButtonBox>
                                <Button to="/technology" margin="0 auto" isLink>Find out more</Button>
                            </ButtonBox>
                        </>
                    )
                }
            </Container>
        )
    }
}