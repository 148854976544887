import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import styled from "styled-components"
import ThreeCol from "../components/threeCol/threeCol"
import TxtImg from "../components/txtImg/txtImg"
import DayNight from "../components/dayNight/dayNight"
import CSCircle from "../images/CS_circle.svg"
import { Helmet } from "react-helmet"

const BgGradientGrey = styled.section`
  background-image: linear-gradient(1.5deg, var(--neutral-200) 8%, var(--neutral-300) 8.2%)!important;
  padding-bottom: 5em;
  margin-bottom: -2em;

  @media only screen and (min-width: 900px) {
    margin-bottom: -1em;
    padding-bottom: 2.5em;
  }
`;

const BgGradientOrange = styled.section`
  background-image: linear-gradient(-1.5deg, var(--accent-orange-600) 5%, var(--neutral-100) 5.2%)!important;
  padding-bottom: 6em;

  @media only screen and (min-width: 900px) {
    padding-bottom: 5em;
  }
`;

const BgOrange = styled.section`
  background-color:hsl(22, 96%, 48%);
  padding-bottom: 6em;
  margin-bottom: -2em;
  background-image: linear-gradient(1deg, var(--neutral-100) 5%, var(--accent-orange-600) 5.2%)!important;

  @media only screen and (min-width: 600px) {
    margin-bottom: -1em;
  }

  @media only screen and (min-width: 900px) {
    margin-bottom: 0;
  }
`;

const TxtImgArea = styled.div`
    width: 80%;
    max-width: 1080px;
    margin: auto;
    padding-bottom: 3em;
    display: block;
    align-items: center;
    justify-content: space-between;
    @media only screen and (min-width: 980px) {
        display: flex;
    }
`;

const TxtContainer = styled.div`
    width: 100%;
    h2 {
      font-size:18px;
      color: #333333;
    }
    @media only screen and (min-width: 1280px){
       width: 57.8%;
    }
    @media only screen and (min-width: 980px) and (max-width: 1280px){
      width: 47.25%;
    }
`;

const ImgContainer = styled.div`
    width: 100%;
    max-height: 900px;
    @media only screen and (min-width: 1280px){
       width: 36.7%;
    }
    @media only screen and (min-width: 980px) and (max-width: 1280px){
       width: 47.25%;
    }
    @media only screen and (max-width: 979px) {
       width: 90%;
       margin: auto;
       padding-top: 1.5em;
    }
`;

export default function Technology({ data }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Core Technology | Caplin Solar</title>
        <html lang="en"></html>
      </Helmet>
      <BgGradientGrey>
        <TxtImg heading="Core Technology" headingColor="accent" imgSrc={data.houseImage.childImageSharp.fluid} imgAlt="Illustration of a house using the Caplin Solar system">
          <p>Caplin Solar have developed a complete integrated renewable system, in which three core technologies are brought together to solve the fundamental renewable energy dilemma: Seasonal imbalance.</p>
          <p>Most existing renewable technologies work well in summer, when there is least energy demand, but perform badly in winter, when they are needed most. By collecting and storing summertime​ heat for use during the winter efficient running is maintained all year round.</p>
        </TxtImg>
        <p className="stdWidth" style={{marginTop: "-2.5rem", paddingBottom: 50}}>The system out-performs all other conventional combinations of renewable technologies, finally making it practical and affordable to achieve zero carbon status for new homes and low-rise commercial developments.</p>
        </BgGradientGrey>
      <ThreeCol type="Technology" concise={true} />
      <BgGradientOrange>
        <div className="stdWidth">
          <h1>How it works</h1>
          <p>An array of hybrid Photovoltaic Thermal (PV-T) panels, which collect both electrical and thermal energy, are installed in the roofline of the building. These are used to generate heat to warm the house and its hot water system, as well as provide electricity to run the system and for lighting.</p>
          <p>The system utilises a patented energy storage method, the Earth Energy Bank (EEB). The heated​ fluid is pumped from the roof through a series of pipes underneath the house, warming the surrounding earth. This heat can then be drawn upon as required via a heat pump during the coldest weeks of the year.</p>
          <p>A sophisticated controller manages the flow of energy within the system. Taking into account the outside and inside temperatures, the occupants’ desired temperature, the temperature of the domestic hot water and the temperature of the EEB, whilst monitoring the energy flow from the PVT panels, the system determines the best energy source to meet demand at least cost.</p>
          <img src={CSCircle} width="100%" alt="" />
        </div>
      </BgGradientOrange>
      <BgOrange>
        <div className="stdWidth" style={{color:"var(--neutral-100"}}>
          <h1 style={{textAlign:"center", marginTop:"0"}}>Build Fabric</h1>
          <div className="underline center" style={{borderTop: "3px solid var(--neutral-100)"}}></div>
          <p>When employed in houses with the appropriate building fabric, our thermal storage system can give better returns on investment than​ any other combination of renewable technologies.</p>
          <p>Efficient low energy homes are a marriage between build fabric and renewable energy provision. Both are equally important. The challenge lies in finding the right level of each to give the lowest capital and lifetime running cost. Current building regulations, though much improved over the past ten years, fall short of this ideal level. At the other extreme, building to Passivhaus​ standards places too great a cost burden on the building’s fabric​ and therefore is not cost-effective and poorly suited to volume building.</p>
          <p>The Earth Energy Bank system by Caplin Solar finds this heat loss balance at around 25 watts p sq m, allowing for ease of build and minimising the cost of the renewable technology. This target is best realised by the use of modular or panelised systems like timber frame, where insulation is integrated within the structure, rather than between elements of the structure. This provides greater efficiency also allowing for thinner external walls with a consequent increase in sellable internal floor space.</p>
        </div>
      </BgOrange>
      <section style={{backgroundColor: "var(--neutral-100"}}>
        <div className="stdWidth">
          <h1>Day / Night</h1>
          <p>In daytime mode the PV-T panels will collect light in the visible spectrum and heat in the infra-red spectrum. In the middle of England, the total energy reaching a single panel is about 2,000 KWH of which about 340 KWH (17%) in in the form of light producing electrical energy and about 1000 KWH (51%) in the form of heat producing thermal energy. These outputs exceed what can be expected from conventional PV and Solar Thermal panels because they are being kept cool from circulation from the heat store (EEB). The output from photovoltaic cells degrades as they get hot and solar thermal panels re-radiate some of their heat back into space and suffer from wind chill when they get hot.</p>
          <p>This means that a PV-T panel will collect no less than 4 times the energy from the sun as a simple PV panel, when used in this way. That can provide enough thermal energy to produce space heating and hot water all year round as well as the electrical power to run the heat pump and all the other electrical devices associated with heating and lighting from a comparatively modest roof area.</p>
        </div>
        <TxtImgArea>
          <TxtContainer>
              <p>In night-time mode these panels can do some even more remarkable things. They can act as heat absorbers whenever that air temperature is greater than​ the ground temperature in the heat store.</p>
              <p>During the winter, when the panel temperature is less than that of the EEB, the panels will be omitted from the circulation and the energy taken only from the ground. As the energy stored in the EEB becomes depleted, so its temperature falls and that of the circulating fluid falls even further. Under these conditions​,​ the cold fluid coming out of the heat pump is diverted to the PV-T array where thermal energy can be extracted from the air through both front and rear faces of the panels. Thermal insulation is omitted from the back face of the panels to maximise energy absorption. This significantly extends the life of the thermal store and the process can last continuously over several nights and days in mid-winter.</p>
          </TxtContainer>
          <ImgContainer>
            <DayNight></DayNight>
          </ImgContainer>
          </TxtImgArea>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    houseImage: file(relativePath: {eq: "images/CS_house.png"}) {
      childImageSharp {
        fluid(maxWidth: 780, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`